import {checkNullOrEmptyValue} from '../../../utility/validations';

export const merchantModel = data => {
  const newData = data?.data;
  const merchantData = data?.data?.data;
  const payload = {};

  payload.data = parseMerchantsItems(merchantData);
  payload.error = checkNullOrEmptyValue(newData?.error);
  payload.meta = checkNullOrEmptyValue(newData?.meta);
  payload.message = checkNullOrEmptyValue(newData?.message);
  payload.success = data.success;
  payload.status = data.status;

  return payload;
};

// marchants []
const parseMerchantsItems = (resultsData = []) => {
  const payload = [];

  resultsData.forEach(item => {
    const resultItem = {};

    resultItem.id = checkNullOrEmptyValue(item?.id);
    resultItem.midNum = checkNullOrEmptyValue(item?.midNum);
    resultItem.dba = checkNullOrEmptyValue(item?.dba);
    resultItem.descriptor = checkNullOrEmptyValue(item?.descriptor);
    resultItem.bank = checkNullOrEmptyValue(item?.bank);
    resultItem.minTransactionAmount = checkNullOrEmptyValue(
      item?.minTransactionAmount,
    );
    resultItem.maxTransactionAmount = checkNullOrEmptyValue(
      item?.maxTransactionAmount,
    );
    resultItem.phoneNum = checkNullOrEmptyValue(item?.phoneNum);
    resultItem.merchantName = checkNullOrEmptyValue(item?.client?.merchantName);
    resultItem.clientCrmCount = checkNullOrEmptyValue(
      item?.client?.clientCrmCount,
    );

    payload.push(resultItem);
  });

  return payload;
};
